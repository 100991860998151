<template>
    <div class="bigwrapper">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="bread">
            <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
            <el-breadcrumb-item v-if="showPageA" :replace="true" :to="{ path: '/goods' }">products</el-breadcrumb-item>
            <el-breadcrumb-item v-if="showPageB" :to="{ path: '/quick' }">Order</el-breadcrumb-item>
            <el-breadcrumb-item v-if="showPageC" :to="{ path: '/personalcenter/Cart' }">Shopping cart</el-breadcrumb-item>
            <el-breadcrumb-item>details</el-breadcrumb-item>
        </el-breadcrumb>
        <div
            style="margin: 20px 0 20px 16px;cursor: pointer;display: flex;justify-content: space-between;align-items: center;">
            <div @click="goBack" style="font-size: 20px;color: #545454;">&lt; Back</div>
            <div>
                <!-- <button class="export" :disabled="isClick1" @click="downLoads()">
                    <img src="../assets/imgs/uploads/tabler_download.png" alt="" style="width: 15px;height: 15px;">
                    Download Marketing Kit</button> -->
            </div>
        </div>

        <div style="display: flex;">
            <div style="flex: 1;">
                <div id="slider-container">
                    <div id="slider">
                        <div class="large-image-container">
                            <video controls v-if="selectedImage == 'mp4'"
                                style="width: 100%; height:100%; border-radius: 10px;" autoplay>
                                <source :src="this.images[this.selectedIndex1]" type="video/mp4">
                            </video>
                            <div v-if="selectedImage == 'image' && this.images.length > 0">
                                <div style="position: relative;">
                                    <!-- <magnifying :url="this.images[this.selectedIndex1]"></magnifying> -->
                                    <!-- <img class="img-container" v-lazy="this.images[this.selectedIndex1]" /> -->
                                    <div class="left">
                                        <img class="leftImg" v-lazy="this.images[this.selectedIndex1]" alt="" />
                                        <!-- 鼠标层罩 -->
                                        <div v-show="topShow" class="top" :style="topStyle"></div>
                                        <!-- 最顶层覆盖了整个原图空间的透明层罩 -->
                                        <div class="maskTop" @mouseenter="enterHandler" @mousemove="moveHandler"
                                            @mouseout="outHandler"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="thumbnail-container">
                    <div class="thumbnail-container">
                        <div v-for="(image, index) in images" :key="index">
                            <div v-if="fileSubstr(image) == 'mp4'" :class="{ 'active': selectedIndex1 === index }"
                                @mouseover="showLargeImage(index)" class="smallmp4">
                                <video style="width: 100% ; height:100%;object-fit: fill;border-radius: 10px;">
                                    <source :src="image" type="video/mp4">
                                </video>
                                <div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);">
                                    <img src="../assets/imgs/uploads/videoct.png" style="width: 90px;height: 90px;" alt="">
                                </div>
                            </div>
                            <img v-else v-lazy="image" @mouseover="showLargeImage(index)"
                                :class="{ 'active': selectedIndex1 === index }" />
                        </div>
                    </div>
                </div>
            </div>
            <div style="flex: 1;margin-left: 80px;position: relative;max-width: 600px;">
                <div v-show="rShow" class="right">
                    <img :style="r_img" class="rightImg" v-lazy="this.images[this.selectedIndex1]" alt="" />
                </div>
                <div style="margin-bottom: 10px;">
                    <img v-if="productDetail.brand == 'Gigwi'" src="../assets/imgs/uploads/Rectangle 139.png" alt=""
                        style="width: 101px;height: 42px;">
                    <img v-if="productDetail.brand == 'SKIPDAWG'" src="../assets/imgs/uploads/Rectangle 132.png" alt=""
                        style="width: 190px;height: 42px;">
                    <img v-if="productDetail.brand == 'PETGEEK'" src="../assets/imgs/uploads/Rectangle 131.png" alt=""
                        style="width: 190px;height: 42px;">
                    <img v-if="productDetail.brand == 'Gifts For Paws'" src="../assets/imgs/uploads/Rectangle 13.png" alt=""
                        style="width: 190px;height: 42px;">
                </div>
                <p style="font-size: 24px; font-weight: bold;">
                    {{ productDetail.spuName }}</p>
                <div style="margin-top: 21px;font-size: 18px;color: #959595;">OPTION ·
                    <span style="color: #545454;">{{ productSkuInfo.name }}</span>
                </div>
                <div style="display: flex;flex-wrap: wrap; margin-top: 12px;">
                    <div class="opn" v-for="(item, index) in models" :key="index" @click="selectOption(index)"
                        :class="{ 'selected': selectedIndex === index }">
                        <div style="display: flex; justify-content: center;"><img style="width: 70px; height: 70px;"
                                v-lazy="item" alt=""></div>
                    </div>
                </div>
                <div v-if="this.$store.getters.getUser">
                    <div style="margin-top: 5px;color: #545454;font-size: 13px;">Unit Price</div>
                    <div style="font-weight: bold;">$ {{ unit }}</div>
                    <el-divider class="divider"></el-divider>
                    <div style="margin-top: 5px;display: flex;">
                        <div>
                            <div style="color: #545454;font-size: 13px;">Quantity</div>
                            <div style="display: flex;align-items: center;">
                                <el-input-number v-if="isLcl === 0" style="width: 117px;margin-top: 5px;" v-model="num"
                                    size="mini" :step="amount" :min="0" step-strictly>
                                </el-input-number>
                                <el-input-number v-else style="width: 117px;margin-top: 5px;" v-model="num" :min="0"
                                    size="mini">
                                </el-input-number>
                                <div class="status">{{ getLabelForStatus(stus) }}</div>
                            </div>
                        </div>
                        <div style="margin-left: 20px;">
                            <div style="color: #545454;font-size: 13px;">Subtotal</div>
                            <div style="font-size: 22px;font-weight: bold;">${{ formatF((unit * 1000) * num / 1000) }}</div>
                        </div>
                    </div>
                    <div style="font-size: 12px;color: #545454;margin-top: 10px;">
                        MOQ : {{ amount }} Piece
                    </div>
                    <div style="display: flex;margin-top: 20px;align-items: center;">
                        <button :disabled="isClick" class="btn" @click="addCart()">
                            Add to Cart
                        </button>
                        <div v-if="this.likeStatus === '0'" style="margin-left: 26px;cursor: pointer;">
                            <img :src="currentImage1" @click="likes" @mouseover="hoverImage('image1', true)"
                                @mouseleave="hoverImage('image1', false)" style="width: 61px;height: 47px;">
                            <img :src="currentImage2" @click="dislikes" @mouseover="hoverImage('image2', true)"
                                @mouseleave="hoverImage('image2', false)" style="width: 61px;height: 47px;">
                        </div>
                        <div v-if="this.likeStatus === '1'" style="margin-left: 26px;cursor: pointer;display: flex;">
                            <div
                                style="width: 85px;height: 47px;border-radius: 25px 0 0 25px;background-color: #E4E4E4;display: flex;align-items: center;">
                                <img src="../assets/imgs/uploads/like.png" alt=""
                                    style="width: 24px;height: 24px;margin-left: 19px;">
                                <span style="color: #959595;font-weight: bold;margin-left: 3px;">{{ likNum }}</span>
                            </div>
                            <div
                                style="width: 85px;height: 47px;border-radius: 0 25px 25px 0;background-color: #E4E4E4;
                                                                    margin-left: 2px;display: flex;justify-content: flex-end;align-items: center;">
                                <span style="color: #959595;font-weight: bold;margin-left: 8px;">{{ dlikNum }}</span>
                                <img src="../assets/imgs/uploads/dislike1.png" alt=""
                                    style="width: 24px;height: 24px;margin-left: 3px;margin-right: 19px;">
                            </div>
                        </div>
                        <div v-if="this.likeStatus === '2'" style="margin-left: 26px;cursor: pointer;display: flex;">
                            <div
                                style="width: 85px;height: 47px;border-radius: 25px 0 0 25px;background-color: #E4E4E4;display: flex;align-items: center;">
                                <img src="../assets/imgs/uploads/like1.png" alt=""
                                    style="width: 24px;height: 24px;margin-left: 19px;">
                                <span style="color: #959595;font-weight: bold;margin-left: 3px;">{{ likNum }}</span>
                            </div>
                            <div
                                style="width: 85px;height: 47px;border-radius: 0 25px 25px 0;background-color: #E4E4E4;
                                                            margin-left: 2px;display: flex;justify-content: flex-end;align-items: center;">
                                <span style="color: #959595;font-weight: bold;margin-left: 8px;">{{ dlikNum }}</span>
                                <img src="../assets/imgs/uploads/dislike.png" alt=""
                                    style="width: 24px;height: 24px;margin-left: 3px;margin-right: 19px;">
                            </div>
                        </div>
                    </div>

                </div>
                <div style="margin-top: 46px;">
                    <div style="margin-bottom: 20px;" v-for="(item, index) in messages" :key="index">
                        <ul style="list-style: disc;color: #333;">
                            <li>{{ item }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin: 50px 0; border-bottom: 1px solid #ccc;"></div>
        <div>
            <div style="font-weight: bold; font-size: 20px;">Specification</div>
            <div style="display: flex;">
                <div style="flex: 1;">
                    <div class="attb">
                        <div><span class="Attributes">Item No</span>{{ productSkuInfo.customOddNo }}</div>
                        <div><span class="Attributes">Materials</span>{{ productSkuInfo.materialQuality }}</div>
                        <div><span class="Attributes">Category</span>{{ productSkuInfo.categoryName }}</div>
                        <div><span class="Attributes">Product Contain</span>
                            <span>{{ productSkuInfo.productContain }}</span>
                        </div>
                    </div>
                </div>
                <div style="flex: 1;">
                    <div class="attb">
                        <div><span class="Attributes">Product Size</span>{{ productSkuInfo.size }}</div>
                        <div><span class="Attributes">Carton Weight</span>{{ productSkuInfo.heft }} KG</div>
                        <div><span class="Attributes">Carton Dimensions</span>{{ productSkuInfo.boxSize }}</div>
                        <div><span class="Attributes">Quantity In The Carton</span>{{ productSkuInfo.amount }} pieces</div>
                    </div>
                </div>

            </div>
        </div>
        <div style="margin: 20px 0; border-bottom: 1px solid #ccc;"></div>
        <div style="margin-top: 50px;">
            <div style="font-weight: bold; font-size: 20px;">Product Details</div>
        </div>
        <div style="width: 960px;margin:36px auto 0"
            v-html="productDetail.spuInfos && productDetail.spuInfos.length > 0 ? productDetail.spuInfos[0] : ''">
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import { saveAs } from 'file-saver';
export default {
    metaInfo() {
        return {
            meta: [
                {
                    name: 'keywords',
                    content: this.brand + ',' + this.spuName,
                }
            ]
        }
    },
    data() {
        return {
            unit: 0,
            radio: '',
            dis: false, // 控制“加入购物车按钮是否可用”
            spuId: "", // 商品id
            productDetail: "",
            productSkuInfo: "",
            options: '', // 商品详细信息
            models: [],
            skus: '',
            messages: [],
            images: [],
            selectedIndex1: 0,
            selectedIndex: 0,
            stus: '',
            num: 0,
            isLcl: '',
            amount: 0,
            skuId: 0,
            stockNum: 0,
            isClick: false,
            isClick1: false,
            showPageA: false,
            showPageB: false,
            showPageC: false,
            timer: null,
            brand: '',
            spuName: '',
            topStyle: {
                transform: ""
            },
            r_img: {
            },
            topShow: false,
            rShow: false,
            normalSrc1: require('../assets/imgs/uploads/Group 607.png'),
            hoverSrc1: require('../assets/imgs/uploads/Group 605.png'),
            normalSrc2: require('../assets/imgs/uploads/Group 608.png'),
            hoverSrc2: require('../assets/imgs/uploads/Group 606.png'),
            currentImage1: require('../assets/imgs/uploads/Group 607.png'),
            currentImage2: require('../assets/imgs/uploads/Group 608.png'),
            likeStatus: '0',
            likNum: 0,
            dlikNum: 0,
        };
    },
    // 通过路由获取商品id
    activated() {
        if (this.$route.query.spuId != undefined) {
            this.spuId = this.$route.query.spuId;
        }
        this.showPageA = false
        this.showPageB = false
        this.showPageC = false
        // 根据路由等条件动态设置是否显示对应的面包屑导航项
        if (this.$route.path === '/goods/details') {
            this.showPageA = true;
        } else if (this.$route.path === '/quick/details') {
            this.showPageB = true;
        } else if (this.$route.path === '/personalcenter/Cart/details') {
            this.showPageC = true;
        }
        // 设置计时器，在页面加载后开始计时
        this.timer = setTimeout(() => {
            this.recordVisit();
        }, 30000); // 30秒后执行记录
    },
    mounted() {
        // this.getDetails();
    },
    watch: {
        // 监听商品id的变化，请求后端获取商品数据
        spuId: function (val) {
            this.getDetails(val);
        }
    },
    destroyed() {
        // 清除计时器，在组件销毁时清除计时器，以防止内存泄漏
        clearTimeout(this.timer);
    },
    methods: {
        ...mapActions(["unshiftShoppingCart", "addShoppingCartNum", "setShoppingCart"]),
        // 获取商品详细信息
        getDetails() {
            this.images = []
            this.messages = []
            this.models = []
            this.skus = ''
            this.num = 0
            this.amount = 0
            this.skuId = 0
            this.stockNum = 0
            this.brand = ''
            this.$axios
                .post("/api/mall/spu/detail/",
                    {
                        spuId: this.spuId,
                        memberId: this.$store.getters.getUser.id,
                    }).then(res => {
                        this.productDetail = res.data.data
                        this.brand = this.productDetail.brand
                        this.spuName = this.productDetail.spuName
                        this.images = this.productDetail.skus[0].skuImages
                        this.productSkuInfo = this.productDetail.skus[0].sku
                        this.messages = this.productDetail.descriptions;
                        this.skus = this.productDetail.skus
                        this.models = this.productDetail.skus.map(obj => obj.imgUrl)
                        this.stus = this.skus[0].sku.status
                        this.unit = this.skus[0].sku.price
                        this.num = this.skus[0].sku.memberNum
                        this.isLcl = this.skus[0].sku.isLcl
                        this.amount = this.skus[0].sku.amount
                        this.skuId = this.skus[0].sku.id
                        this.stockNum = this.skus[0].sku.stockNum
                        var data = this.productDetail.skus;
                        if (this.productDetail.spuVideo) {
                            for (let index = 0; index < data.length; index++) {
                                data[index].skuImages.unshift(this.productDetail.spuVideo)
                            }
                        }
                        this.getLikes()
                    })
                .catch(err => {
                    return Promise.reject(err);
                });
        },
        goBack() {
            this.$router.go(-1)
        },

        selectOption(index) {
            this.selectedIndex = index;
            const selectedSku = this.productDetail.skus[index]
            const imagesList = selectedSku.skuImages
            if (selectedSku.skuImages && selectedSku.skuImages.length > 0) {
                this.images = imagesList
            }
            this.stus = selectedSku.sku.status
            this.unit = selectedSku.sku.price
            this.num = selectedSku.sku.memberNum
            this.isLcl = selectedSku.sku.isLcl
            this.amount = selectedSku.sku.amount
            this.skuId = selectedSku.sku.id
            this.stockNum = selectedSku.sku.stockNum
            this.productSkuInfo = selectedSku.sku
            this.getLikes()
        },
        showLargeImage(index) {
            this.selectedIndex1 = index;
        },
        addCart() {
            this.isClick = true;
            setTimeout(() => {
                this.isClick = false;
            }, 2000);
            this.$axios
                .post("/api/mall/cart/updateNum", {
                    memberId: this.$store.getters.getUser.id,
                    skuId: this.skuId,
                    num: this.num
                }).then(res => {
                    if (res.data.code === 200) {
                        this.$axios
                            .post("/api/mall/cart/" + this.$store.getters.getUser.id)
                            .then(res => {
                                if (res.data.code === 200) {
                                    // 001 为成功, 更新vuex购物车状态
                                    this.$message({
                                        showClose: true,
                                        message: 'Successfully added!',
                                        type: 'success'
                                    });
                                    this.setShoppingCart(res.data.data);
                                    this.$set(this.productDetail.skus[this.selectedIndex].sku, 'memberNum', this.num)
                                } else {
                                    // 提示失败信息
                                    this.notifyError(res.data.message);
                                }
                            })
                            .catch(err => {
                                return Promise.reject(err);
                            });
                    }
                })
        },
        getLabelForStatus(status) {
            switch (status) {
                case 0:
                    return this.$t('list.instock');
                case 1:
                    return this.$t('list.production');
                case 2:
                    return this.$t('list.ordertogether');
                default:
                    return 'unknown';
            }
        },
        //下载zip
        downLoads() {
            this.isClick1 = true;
            setTimeout(() => {
                this.isClick1 = false;
            }, 10000);
            this.$axios
                .get("/api/mall/spu/detailZip/" + this.spuId,
                    {
                        responseType: "arraybuffer",
                        headers: { 'content-Type': 'application/json; application/octet-stream' }
                    })
                .then((response) => {
                    this.saveFile(response.data);
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        },
        saveFile(data) {
            const blob = new Blob([data], { type: 'application/zip' }); // 创建一个二进制 Blob
            const fileName = 'downloadedFile.zip'; // 指定下载文件的名称

            saveAs(blob, fileName); // 使用 FileSaver.js 保存文件
        },
        recordVisit() {
            // 记录用户访问信息，可以发送网络请求或者调用其他逻辑
            this.$axios
                .post("/api/mall/log/save",
                    {
                        userId: this.$store.getters.getUser.id ? this.$store.getters.getUser.id : '',
                        userName: this.$store.getters.getUser.nickName ? this.$store.getters.getUser.nickName : '',
                        brand: this.brand,
                        spuId: this.spuId,
                        spuName: this.spuName
                    })
        },
        formatF(value) {
            let value1 = Math.round(parseFloat(value) * 100) / 100;
            let s = value.toString().split(".");
            if (s.length == 1) {
                value1 = value.toString() + ".00";
                return value1;
            }
            if (s.length > 1) {
                if (s[1].length < 2) {
                    value1 = value.toString() + "0";
                }
                return value1;
            }
        },
        fileSubstr(str) {
            if (str) {
                let index = str.lastIndexOf(".");
                return str.substr(index + 1, str.length)
            }

        },
        // 鼠标进入原图空间函数
        enterHandler() {
            // 层罩及放大空间的显示
            this.topShow = true;
            this.rShow = true;
        },
        // 鼠标移动函数
        moveHandler(event) {
            // 鼠标的坐标位置
            let x = event.offsetX;
            let y = event.offsetY;
            // 层罩的左上角坐标位置，并对其进行限制：无法超出原图区域左上角
            let topX = x - 150 < 0 ? 0 : x - 150;
            let topY = y - 150 < 0 ? 0 : y - 150;
            // 对层罩位置再一次限制，保证层罩只能在原图区域空间内
            if (topX > 300) {
                topX = 300;
            }
            if (topY > 300) {
                topY = 300;
            }
            // 通过 transform 进行移动控制
            this.topStyle.transform = `translate(${topX}px,${topY}px)`;
            this.r_img.transform = `translate(-${2 * topX}px,-${2 * topY}px)`;
        },
        // 鼠标移出函数
        outHandler() {
            // 控制层罩与放大空间的隐藏
            this.topShow = false;
            this.rShow = false;
        },
        hoverImage(imageGroup, isHovering) {
            if (imageGroup === 'image1') {
                this.currentImage1 = isHovering ? this.hoverSrc1 : this.normalSrc1;
            } else if (imageGroup === 'image2') {
                this.currentImage2 = isHovering ? this.hoverSrc2 : this.normalSrc2;
            }
        },
        getLikes() {
            this.$axios
                .post("/api/mall/likes/getLikes", {
                    spuId: this.spuId,
                    userId: this.$store.getters.getUser.id,
                    skuId: this.skuId,
                })
                .then(res => {
                    if (res.data.data) {
                        this.likeStatus = res.data.data.status;
                        this.likNum = res.data.data.likes
                        this.dlikNum = res.data.data.unLikes
                    }
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        },
        likes() {
            this.$axios
                .post("/api/mall/likes/likes", {
                    spuId: this.spuId,
                    userId: this.$store.getters.getUser.id,
                    status: '1',
                    skuId: this.skuId,
                })
                .then(() => {
                    this.likeStatus = '1'
                    this.getLikes()
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        },
        dislikes() {
            this.$axios
                .post("/api/mall/likes/likes", {
                    spuId: this.spuId,
                    userId: this.$store.getters.getUser.id,
                    status: '2',
                    skuId: this.skuId,
                })
                .then(() => {
                    this.likeStatus = '2'
                    this.getLikes()
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        },
    },
    computed: {
        selectedImage() {
            if (this.fileSubstr(this.images[this.selectedIndex1]) == "mp4") {
                return "mp4";
            } else {
                return "image";
            }


        },
    },

};
</script>
<style scoped>
/* 头部CSS */
/* 产品详情的样式表 */
.bigwrapper {
    width: 1280px;
    margin: 0 auto 64px;
}

::v-deep .el-breadcrumb__inner.is-link:hover {
    color: #000;
}

.bread {
    margin-top: 30px;
    margin-left: 16px;
}

#slider-container {
    position: relative;
    max-width: 600px;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

#slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.slide {
    min-width: 100%;
}

.selected {
    color: #000;
    border-color: #CA141D !important;
}

#prev,
#next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    cursor: pointer;
}

#prev {
    left: 10px;
}

#next {
    right: 10px;
}

.thumbnail-container {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.thumbnail {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin: 0 8px;
    cursor: pointer;
}

.active {
    border: 1px solid #CA141D !important;
}

.sizeOpthion {
    width: 60px;
    height: 30px;
    line-height: 30px;
    padding: 0 8px;
    border-radius: 3px;
    border: 1px solid #ccc;
    margin: 10px;
}

.opn {
    width: 77px;
    margin-right: 15px;
    margin-bottom: 15px;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.large-image-container {
    width: 600px;
    /* 设置大图片容器的宽度 */
    height: 600px;
    /* 设置大图片容器的高度 */
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    overflow: hidden;
    /* 确保大图片在容器内显示 */
}

.img-container {
    width: 600px;
    height: 600px;
    border-radius: 10px;
    object-fit: cover;
    position: relative;
    /* 图片等比例缩放，保持完整显示 */
}

.thumbnail-container img {
    width: 90px;
    /* 设置小图片的宽度 */
    height: 90px;
    /* 设置小图片的高度 */
    margin: 0 6px;
    border-radius: 10px;
    /* 设置小图片之间的间距 */
    cursor: pointer;
}

/* ::v-deep .el-input-number__decrease {
    background: #000;
    color: #FFF;
}

::v-deep .el-input-number__increase {
    background: #000;
    color: #FFF;
} */

::v-deep .el-input-number__decrease:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled),
.el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled) {
    border-color: #959595;
}

::v-deep .el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled),
.el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled) {
    border-color: #959595;
}

/* ::v-deep .el-input__inner {
    border: 1px solid #000;
} */

.status {
    margin-left: 14px;
    width: 54px;
    height: 18px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    border-radius: 18px;
    color: #333;
    border: 1px solid #333;
}

.attb {
    margin-top: 10px;
    color: #333;
}

.attb>div {
    margin: 30px 0;
}

.Attributes {
    display: inline-block;
    width: 200px;
    font-weight: bold;
}

.main {
    margin-top: 100px auto 0;
    width: 1500px;
}

.divider {
    margin: 10px 0;
}

.btn {
    width: 296px;
    height: 47px;
    background-color: #CA141D;
    border: 1px solid #CA141D;
    color: #FFF;
    font-size: 20px;
    font-weight: bold;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.btn:hover {
    background-color: #F27279;
    border: 1px solid #F27279;
}

.smallmp4 {
    border: 1px solid #FFF;
    margin: 0 8px;
    border-radius: 10px;
    cursor: pointer;
    width: 90px;
    height: 90px;
    position: relative;
}

.export {
    width: 189px;
    height: 36px;
    margin-right: 15px;
    margin-left: 22px;
    border: 1px solid #FFA9A4;
    border-radius: 5px;
    background-color: #FFE7E5;
    color: #CA141D;
    cursor: pointer;
}

/* 放大的图片，通过定位将左上角定位到(0,0) */
.rightImg {
    display: inline-block;
    width: 1200px;
    height: 1200px;
    position: absolute;
    top: 0;
    left: 0;
}

/* 右边的区域图片放大空间 */
.right {
    position: absolute;
    position: relative;
    top: 0%;
    left: -5%;
    width: 600px;
    height: 600px;
    border: 1px solid #CA141D;
    overflow: hidden;
    z-index: 10;
}

/* 一个最高层层罩 */
.maskTop {
    width: 600px;
    height: 600px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
}

/* 层罩，通过定位将左上角定位到(0,0) */
.top {
    width: 300px;
    height: 300px;
    background-color: lightcoral;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
}

/* 原图的显示 */
.leftImg {
    width: 600px;
    height: 600px;
}

/* 原图的容器 */
.left {
    width: 600px;
    height: 600px;
    position: relative;
}

/* 主要内容CSS END */
</style>